<template>
	<template-base class="containerCard">
		<div>
			<div class="container-head">
				<div
					v-if="possuiPermissao('GER_I_CATEGORIAS')"
					class="container-add-new"
					v-bind:class="!categoryExpenses.length ? 'container-add-new-100' : ''"
				>
					<b-button class="add-button" @click="mostrarEdicao({})">
						Adicionar
					</b-button>
				</div>
			</div>
			<div v-if="categoryExpenses.length" class="head-row mb-2 width-pagination-items">
				<div class="text-head-row width-pagination">
					<label class="text-head-row ml-0">
						Resultado {{ pagination.offset + 1 }}-{{ pagination.offset + categoryExpenses.length }} de {{ pagination.count }}
					</label>
					<b-pagination
						class="mr-0 mb-2"
						id="page"
						v-model="currentPage"
						:total-rows="pagination.count"
						:per-page="pagination.limit.value"
					></b-pagination>
				</div>
				<b-form
					class="text-head-row width-pagination">
					<label class="text-head-row ml-0">
						Itens por página
					</label>
					<b-form-select
						class="mr-0 mb-2"
						v-model="pagination.limit.value"
						name="itens-por-pagina"
						:options="optionsItensPorPag"
						@input="changeItensByPage()"
						:disabled="loading"
					/>
				</b-form>
			</div>
			<b-modal
				ref="modalEdicao"
				hide-footer
				:title="titleModalEdicao"
				size="md"
			>
				<div class="modal-edicao d-block mb-3 modal-edicao-label">
					<b-row>
						<b-col cols="12" class="d-block text-parcela">
							<span class="label-conta">Categoria da Conta</span>
							<b-form-input
								name="nome_categoria"
								class="modal-edicao-input"
								type="text"
								v-model="categoryExpenseSelecionado.description"
								autocomplete="off"
								placeholder="Digite o nome do categoria de transação"
								v-validate="{ required: true }"
								:state="validateState('nome_categoria')"
							/>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="6" class="d-block text-parcela">
							<span class="label-conta">Permite Renda</span>
							<ToggleSwitch
								name="category-income"
								class="toggle-categoria"
								:value="categoryExpenseSelecionado.income"
								@toggle="_ => changeCategory(categoryExpenseSelecionado.income)"
							/>
						</b-col>
					</b-row>
				</div>
				<b-button class="ml-1 mt-3 float-left btn-modal btn-cancel" @click="fecharModal('modalEdicao')">Cancelar</b-button>
				<b-button class="mt-3 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
				<b-button
					class="mt-3 ml-1 float-right btn-modal btn-salvar"
					@click="salvarEdicao"
					:disabled="invalidForm"
				>
					Salvar
				</b-button>
			</b-modal>
		</div>
		<div v-if="categoryExpenses.length" class="table-responsive">
			<DataTable v-if="!isMobile"
				class="data-table"
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:nosearch="true"
				:nofilters="true"
				name="categoriaContas"
				:hasPagination="true"
				:noedit="true"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_CATEGORIAS')"
				:permissaoDelete="possuiPermissao('GER_D_CATEGORIAS')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
				@clickSortTableBy="(v) => sortTableBy(v)"
				:state="{
					sortBy: sortTable.sortBy,
					sortAsc: sortTable.order === 1,
					query: ''
				}"
				:async="true"
			/>
			<CardTransaction v-else
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_CATEGORIAS')"
				:permissaoDelete="possuiPermissao('GER_D_CATEGORIAS')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
				:selectItem="false"
				:globalAcoes="false"
				@clickCheck="false"
				:colsDataCard="colsDataCard"
				:details="false"
				:showSelectItem="false"
			/>
		</div>
		<div v-else class="alert alert-warning" role="alert">
			{{ errMsg }}
		</div>
	</template-base>
</template>

<script>
	import ToggleSwitch from "@/components/ToggleSwitch";

	import DataTable from "@/components/DataTable";
	import TemplateBase from "@/templates/Base";

	import { possuiPermissao } from "../../helpers/permissions";
	import { CategoryExpensesService } from "../../services/categoryExpenses";
	import { hasFieldsWithErrors } from "@/helpers/validators";
	import CardTransaction from "@/components/CardTransaction.vue";

	export default {
		components: {
			TemplateBase,
			ToggleSwitch,
			DataTable,
			CardTransaction
		},

		inject: ["parentValidator"],

		data () {
			return {
				categoryExpenses: [],
				linhas: [],
				titleModalEdicao: "",
				loading: true,
				categoryExpenseSelecionado: {
					idCategoryExpense: "",
					description: "",
					income: false
				},

				invalidForm: true,
				categoryExpensesService: new CategoryExpensesService(),

				errMsg: "Nenhuma categoria de transação cadastrada!",
				colunas: [
					"Categoria da Transação",
					"Permite Renda"
				],
				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],
				possuiPermissao,
				sortTable: {
					sortBy: 0,
					order: 1
				},
				isMobile: false,
				colsDataCard: {
					0: {
						idx: 0,
						label: "Categoria da Transação",
						textAlign: "left",
						cols: 9,
						visible: true
					},
					1: {
						idx: 1,
						label: "Permite Renda",
						textAlign: "center",
						cols: 3,
						visible: true
					},
					2: {
						idx: 2,
						label: "",
						textAlign: "center",
						cols: 3,
						visible: false
					},
					3: {
						idx: 3,
						label: "",
						textAlign: "right",
						cols: 3,
						visible: false
					},
					4: {
						idx: 4,
						label: "",
						textAlign: "left",
						cols: 6,
						visible: false
					},
					5: {
						idx: null,
						label: "",
						textAlign: "",
						cols: 0,
						visible: false
					},
					6: {
						idx: null,
						label: "",
						textAlign: "left",
						cols: 12,
						visible: false
					},
					7: {
						idx: null,
						label: "",
						textAlign: "",
						cols: 0,
						visible: false
					}
				}
			};
		},

		watch: {
			currentPage () {
				this.changePage(this.currentPage);
			}
		},

		created () {
			this.$validator = this.parentValidator;
		},

		async mounted () {
			this.checkIfMobile();
			window.addEventListener("resize", this.checkIfMobile);

			this.findCategoryExpenses();
		},

		beforeDestroy () {
			window.removeEventListener("resize", this.checkIfMobile);
		},

		methods: {
			checkIfMobile	() {
				this.isMobile = window.innerWidth <= 768;
			},

			sortTableBy (v) {
				this.sortTable = v;
				this.findCategoryExpenses();
			},

			changePage () {
				this.pagination.page = this.currentPage;
				this.findCategoryExpenses();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findCategoryExpenses();
			},

			async findCategoryExpenses () {
				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;
				const sortTable = this.sortTable;

				const result = await this.categoryExpensesService.listCategoryExpenses({offset, limit, sortTable}) || {};
				this.categoryExpenses = result.rows || [];
				this.linhas = result.rows.reduce((acc, gu) => [
					...acc,
					{
						idCategoryExpense: gu.idCategoryExpense,
						cols: [
							gu.description,
							gu.income ? "Sim" : "Não"
						]
					}
				], []);

				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
			},

			mostrarEdicao (item_) {
				let item = {};
				if (item_.idCategoryExpense)
					[ item ] = this.categoryExpenses.filter(gu => gu.idCategoryExpense === item_.idCategoryExpense);

				const { idCategoryExpense, description, income = false } = item;

				if (!idCategoryExpense)
					this.titleModalEdicao = "Cadastrar Nova Categoria de Contas";
				else
					this.titleModalEdicao = "Editar Categoria de Contas";

				this.invalidForm = true;
				this.categoryExpenseSelecionado.idCategoryExpense = idCategoryExpense || "";
				this.categoryExpenseSelecionado.description = description || "";
				this.categoryExpenseSelecionado.income = income;
				this.$refs.modalEdicao.show();
			},

			async confirmaApagar (item_) {
				const [ item ] = this.categoryExpenses.filter((gu) => gu.idCategoryExpense === item_.idCategoryExpense);
				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja remover a categoria de transação?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.idCategoryExpense);
			},

			async salvarEdicao () {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				const payload = {
					idCategoryExpense: this.categoryExpenseSelecionado.idCategoryExpense,
					description: this.categoryExpenseSelecionado.description,
					income: this.categoryExpenseSelecionado.income
				};

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						await this.categoryExpensesService.upsertCategoryExpense({payload});

						this.fecharModal("modalEdicao");
						this.findCategoryExpenses();

						return {
							title: "Sucesso!",
							body: "Categoria de transação salva com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar salvar a categoria de transação!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
			},

			async apagar (idCategoryExpense) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						await this.categoryExpensesService.deleteCategoryExpense({idCategoryExpense});
						this.findCategoryExpenses();

						return {
							title: "Sucesso!",
							body: "Categoria de transação deletada com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar deletar a categoria de transação!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			limparModal () {
				this.categoryExpenseSelecionado.description = "";
				this.categoryExpenseSelecionado.income = false;
				this.invalidForm = true;
			},

			changeCategory (status) {
				this.categoryExpenseSelecionado.income = !status;
				this.invalidForm = !this.categoryExpenseSelecionado.description;
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			}
		}
	};
</script>

<style scoped>
	.label-center {
		text-align: center;
	}

	.toggle-categoria {
		margin-top: 7px;
	}

	label {
		width: 160px;
	}

	.container-head {
		display: flex;
		height: 5rem;
		width: 100%;
	}

	.container-pages {
		display: flex;
		width: 70%;
		margin-top: 1.5rem;
	}

	.containerCard {
		max-width: 90.5%;
	}

	.modal-edicao-label {
		font-family: 'Roboto Condensed Regular';
		font-size: 23px;
	}

	.modal-edicao-input {
		font-family: 'Roboto Condensed Regular';
		font-size: 20px;
	}

	.table-body-detalhe {
		padding: 5px 0;
	}

	.container-add-new-100 {
		width: 100% !important;
	}
</style>
